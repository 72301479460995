import Swiper from '@iris.interactive/handcook/public/scripts/libraries/swiper-11'

export default class BlockCustomerNews {
    constructor() {
        if ($('[data-swiper="customer-news-slider"]').length > 0) {
            BlockCustomerNews.buildSlider()
        }
    }

    static async buildSlider() {
        const swiperKey = 'customer-news-slider'
        const slideItem = `[data-swiper="${swiperKey}"] > .block__customer__news__slide`

        const options = {
            mousewheel: {
                forceToAxis: true,
            },
            slidesPerView: 1,
            spaceBetween: 16,
            speed: 1000,
            pagination: {
                el: '.block__customer__news__pagination',
                type: 'bullets',
                clickable: true,
            },
            navigation: {
                nextEl: '.block__customer__news__arrows__navigation-next',
                prevEl: '.block__customer__news__arrows__navigation-prev',
            },
            keyboard: true,
            loop: false,
            freeMode: {
                enabled: true,
            },
            freeModeFluid: true,
            breakpoints: {
                1320: {
                    slidesPerView: 4,
                },
                1024: {
                    slidesPerView: 3,
                },
                650: {
                    slidesPerView: 2,
                },
            },
        }

        if (window.matchMedia('(max-width: 1024px)').matches) {
            options.pagination = false
        }

        $(slideItem).wrapAll('<div class="swiper-wrapper"></div>').addClass('swiper-slide')
        const mySwiper = await Swiper.create(`[data-swiper="${swiperKey}"]`, options)
    }
}

new BlockCustomerNews()
